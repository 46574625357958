import PropTypes from "prop-types"
import React from "react"
import "./eventSection.scss"
//Images

const EventSection = props =>
  !props.hideEventSection && (
    <section className="eventSection">
      <div className="container">
        <div className="eventSection--wrap">
          <div className="eventSection--content">
            {props.title && (
              <h2 className="about-section__title">{props.title}</h2>
            )}
            {props.content && (
              <div
                className="generalContent"
                dangerouslySetInnerHTML={{ __html: props.content }}
              ></div>
            )}
            {props.buttonUrlEvent && props.buttonLabelEvent && (
              <a
                href={props.buttonUrlEvent}
                rel="noreferrer noopener"
                target="_blank"
                className="btn"
              >
                {props.buttonLabelEvent}
              </a>
            )}
          </div>
          <div className="eventSection--thumb">
            {props.imageEvents?.sourceUrl && (
              <img
                className="eventSection--image"
                src={props.imageEvents.sourceUrl}
                alt={props.imageEvents.altText || "Event Image"}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )

EventSection.propTypes = {
  hideEventSection: PropTypes.bool,
  title: PropTypes.string,
  buttonUrlEvent: PropTypes.string,
  buttonLabelEvent: PropTypes.string,
  imageEvents: PropTypes.shape({
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
  }),
}

export default EventSection
